import { Flex, Tag, TagProps } from '@applyboard/crystal-ui'
import { ApplicationState } from '@applyboard/school-applications-types-lib'
import { ApplicationState as AppStatus } from '../../../hooks'

type ApplicationStateTagProps = {
  state?: ApplicationState
  hasOpenDocumentRequests?: boolean
}

export default function ApplicationStateTag(props: ApplicationStateTagProps) {
  const getStateInfo = () => {
    let intent: TagProps['intent']
    let label: string

    switch (props.state) {
      case AppStatus.DRAFT:
        intent = 'warning'
        label = 'Draft'
        break
      case AppStatus.DECLINED:
        intent = 'negative'
        label = 'Declined'
        break
      case AppStatus.PAYMENT_PROCESSING:
        intent = 'magic'
        label = 'Payment Processing'
        break
      case AppStatus.INITIAL_OFFER:
        intent = 'positive'
        label = 'Pre-Offer'
        break
      case AppStatus.WAITLISTED:
        intent = 'secondary'
        label = 'Waitlist'
        break
      case AppStatus.FINAL_OFFER_ISSUED:
        intent = 'positive'
        label = 'Final Offer'
        break
      case AppStatus.DEFERRAL_REQUESTED_IN_ASSESSMENT:
      case AppStatus.DEFERRAL_REQUESTED_IN_SCREENING:
      case AppStatus.DEFERRAL_REQUESTED_IN_INITIAL_OFFER:
        intent = 'warning'
        label = 'Deferral Requested'
        break
      case AppStatus.READY_FOR_ASSESSMENT:
      case AppStatus.READY_FOR_SCREENING:
      default:
        intent = 'primary'
        label = 'Submitted'
        break
    }

    return {
      intent,
      label,
    }
  }

  const { intent, label } = getStateInfo()

  return (
    <Flex direction="column">
      {props.hasOpenDocumentRequests ? (
        <Flex.Item>
          <Tag icon="fill" intent="warning">
            Document Requested
          </Tag>
        </Flex.Item>
      ) : null}
      <Flex.Item>
        <Tag icon="fill" intent={intent}>
          {label}
        </Tag>
      </Flex.Item>
    </Flex>
  )
}
