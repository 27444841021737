import { useAuthMutation } from './auth'
import { ApiStudentRequest, request } from '../utils/request'

type DeletePassportParams = {
  id?: string
  applicationId: string
}

export function useDeletePassport() {
  const { isPending, mutate } = useAuthMutation(async (params: DeletePassportParams) => {
    return await request(
      new ApiStudentRequest(
        `/documents/passports/${params.id}?applicationId=${params.applicationId}`,
        {
          method: 'DELETE',
        },
      ),
      {
        isExpectedResponse: (res): res is any => true,
      },
    )
  })

  return {
    isDeletingPassport: isPending,
    deletePassport: mutate,
  }
}
