import { Spinner } from '@applyboard/crystal-ui'
import styled from '@emotion/styled'

type LoadingProps = {
  size?: 'sm' | 'md' | 'lg' | 'xl'
  height?: 'auto' | '100%' | '100vh'
}

export function Loading({ size = 'xl', height = 'auto' }: LoadingProps) {
  return (
    <SpinnerWrapper height={height}>
      <Spinner size={size} />
    </SpinnerWrapper>
  )
}

const SpinnerWrapper = styled.span<{ height?: string }>`
  display: flex;
  height: ${props => props.height};
  align-items: center;
  justify-content: center;
`
