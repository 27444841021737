import { useNavigate, useSearchParams } from 'react-router-dom'
import { Button, Flex, Heading, Text, useToasts } from '@applyboard/crystal-ui'
import styled from '@emotion/styled'
import { useAuthManager } from '../../hooks'
import { LOCAL_STORAGE_REDIRECT_KEY } from '../../components/ProtectedRoute/ProtectedRoute'

type CongratulationsConfirmationCardProps = {
  newPassword: string
  isOktaSignUp?: boolean
}

export function CongratulationsConfirmationCard(props: CongratulationsConfirmationCardProps) {
  const toast = useToasts()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const { login, isLoggingIn } = useAuthManager()

  const handleGetStarted = () => {
    const redirectTo =
      searchParams?.get('redirectTo') ?? window.localStorage.getItem(LOCAL_STORAGE_REDIRECT_KEY)
    if (redirectTo) {
      window.localStorage.removeItem(LOCAL_STORAGE_REDIRECT_KEY)
      navigate(redirectTo, { replace: true })
    } else {
      navigate('/onboarding', { replace: true })
    }
  }

  return (
    <Flex gap={{ xs: 10, sm: 6 }} align="center" direction="column">
      <img src="/tada-illustration.svg" alt="Congratulations" />
      <Flex gap={6} align="center" direction="column">
        <Heading level={1} variant="headlineM">
          Congratulations
        </Heading>
        <TextWrapper>
          <Text align="center">
            You’re all set! Start exploring your home dashboard and submit your first application.
            Best of luck!
          </Text>
        </TextWrapper>
      </Flex>
      <Button
        intent="primary"
        onClick={
          props.isOktaSignUp
            ? handleGetStarted
            : () => {
                login(
                  {
                    email: searchParams?.get('username') ?? '',
                    password: props.newPassword,
                  },
                  {
                    onSuccess: handleGetStarted,
                    onError: err => {
                      toast.negative(err)
                    },
                  },
                )
              }
        }
        loading={isLoggingIn}
      >
        Get started
      </Button>
    </Flex>
  )
}

const TextWrapper = styled.div(() => ({
  maxWidth: 435,
}))
