import {
  Button,
  createForm,
  Flex,
  Heading,
  TextInput,
  TextInputProps,
  useToasts,
} from '@applyboard/crystal-ui'
import { useForgotPasswordConfirm } from '../../hooks'
import { OnBoardingBox } from '../../components/OnBoardingBox'
import { Navigate, useParams, useSearchParams } from 'react-router-dom'
import { ComponentType, useState } from 'react'
import { ForgotPasswordCongratulationsCard } from './ForgotPasswordCongratulationsCard'
import { isValidPassword } from '../../utils/fieldValidations'

const { Form, Field, useFieldValues } = createForm<{
  newPassword: string
  confirmNewPassword: string
}>()

export function ForgotPasswordResetPage() {
  const toast = useToasts()
  const [success, setSuccess] = useState<boolean>(false)
  const { username } = useParams()
  const [searchParams] = useSearchParams()
  const { forgotPasswordConfirm, isLoadingForgotPasswordConfirm } = useForgotPasswordConfirm()
  const expiryDate = searchParams?.get('expiry')

  if (!expiryDate || new Date(expiryDate) < new Date()) {
    return <Navigate to="/forgot-password?expired=true" state={{ username }} />
  }

  if (success) {
    return <ForgotPasswordCongratulationsCard />
  }

  return (
    <OnBoardingBox>
      <Flex gap={10} direction="column">
        <Flex justify="center">
          <Heading level={1} variant="headlineS">
            Create password
          </Heading>
        </Flex>
        <Form
          defaultValues={{
            newPassword: '',
            confirmNewPassword: '',
          }}
          onSubmit={({ newPassword }) => {
            forgotPasswordConfirm(
              {
                username: username ?? '',
                newPassword,
                confirmationCode: searchParams?.get('code') ?? '',
              },
              {
                onSuccess: () => {
                  setSuccess(true)
                },
                onError: err => {
                  if (err instanceof Error) {
                    toast.negative(err)
                  }
                },
              },
            )
          }}
        >
          <Flex gap={10} direction="column">
            <Field
              as={TextInput as ComponentType<TextInputProps>}
              label="Password"
              name="newPassword"
              type="password"
              validate={password => {
                if (!isValidPassword(password)) {
                  return 'A minimum 8 characters password contains a combination of uppercase and lowercase letters, numbers and symbols.'
                }

                return true
              }}
            />
            <Field
              as={TextInput as ComponentType<TextInputProps>}
              label="Confirm password"
              name="confirmNewPassword"
              type="password"
              validate={(confirmNewPassword, { values }) => {
                if (
                  isValidPassword(values.newPassword) &&
                  confirmNewPassword !== values.newPassword
                ) {
                  return 'Password confirmation does not match.'
                }

                return true
              }}
            />
            <SubmitButton loading={isLoadingForgotPasswordConfirm} />
          </Flex>
        </Form>
      </Flex>
    </OnBoardingBox>
  )
}

type SubmitButtonLoading = {
  loading: boolean
}

function SubmitButton(props: SubmitButtonLoading) {
  const { newPassword, confirmNewPassword } = useFieldValues(['newPassword', 'confirmNewPassword'])
  return (
    <Button
      intent="primary"
      width="fill"
      type="submit"
      disabled={!isValidPassword(newPassword) || newPassword !== confirmNewPassword}
      loading={props.loading}
    >
      Set password
    </Button>
  )
}
