import { Card, Flex, Heading, Text } from '@applyboard/crystal-ui'
import { useQueryClient } from '@tanstack/react-query'
import { FileData } from '@applyboard/school-applications-types-lib'

import {
  RawApplicationAggregationResponse,
  RawApplicationsResponse,
  SchoolAdditionalDocument,
  useUpdateApplication,
} from '../../../hooks'
import {
  DecisionDocumentTags,
  DecisionType,
  DocumentTags,
  StudentDecisionResponse,
} from '../../../utils/enums'
import { getFilesOfType } from '../ApplicationForms/utils'

import { ApplicationDetailCard } from './ApplicationDetailCard'
import { ApplicationDetailDecisionLetterHeading } from './ApplicationDetailDecisionLetterHeading'
import { ApplicationDetailAdditionalDocuments } from './ApplicationDetailAdditionalDocuments'
import { ApplicationDetailCardRejectDialogForm } from './ApplicationDetailCard/ApplicationDetailCardActions/ApplicationDetailCardRejectDialog'
import {
  Decision,
  DecisionResponseType,
} from '@applyboard/school-applications-types-lib/dist/src/backend/applications-api/resources/applications/student-decisions'
import { StudentApplication, StudentApplicationWithFiles } from '../types'

type ApplicationDetailsProps = Readonly<{
  application: StudentApplication
  school: {
    additionalDocuments: SchoolAdditionalDocument[]
  } | null
  onOfferConditionsClick: () => void
}>

export type DecisionData = {
  type?: DecisionType
  hasOfferConditions: boolean
  documentTag: DecisionDocumentTags
  name: string
  url: string
  uploadedAt: Date | null
  decision?: Decision
}

function getTitleByType(type: DecisionDocumentTags): string {
  switch (type) {
    case DocumentTags.PRE_OFFER_LETTER:
      return 'Pre-Offer'
    case DocumentTags.FINAL_OFFER_LETTER:
      return 'Final Offer'
    case DocumentTags.WAITLIST_LETTER:
      return 'Waitlist'
    case DocumentTags.DECLINED_LETTER:
      return 'Declined'
    default:
      return '-'
  }
}

export function ApplicationDetails(props: ApplicationDetailsProps) {
  const queryClient = useQueryClient()

  const application = props.application

  const { isUpdatingApplication, updateApplication } = useUpdateApplication({
    id: props.application.id,
  })

  const studentDecisions = application?.attributes?.studentDecisions
  const hasOfferConditions = !!application?.attributes?.hasOpenDocumentRequests

  const onSuccess = (onSuccessProps: () => void) => (response: RawApplicationsResponse) => {
    queryClient.setQueryData(
      ['applications', response.data.id],
      (oldData: RawApplicationAggregationResponse): RawApplicationAggregationResponse => {
        return {
          data: {
            ...oldData.data,
            attributes: {
              ...oldData.data.attributes,
              application: response.data,
            },
          },
        }
      },
    )
    onSuccessProps()
  }

  const onSubmitAcceptOffer =
    (studentDecision: string | undefined) =>
    ({
      onError,
      onSuccess: onSuccessProps,
    }: {
      onSuccess: () => void
      onError: (err: Error) => void
    }): void => {
      if (!studentDecision) return
      updateApplication(
        {
          attributes: {
            studentDecisions: {
              [studentDecision]: {
                studentResponse: StudentDecisionResponse.ACCEPTED,
                decisionDate: new Date().toISOString(),
              },
            },
          },
        },
        {
          onSuccess: onSuccess(onSuccessProps),
          onError,
        },
      )
    }

  const onSubmitRejectOffer =
    (studentDecision: string) =>
    ({
      data,
      onError,
      onSuccess: onSuccessProps,
    }: {
      onSuccess: () => void
      onError: (err: Error) => void
      data: ApplicationDetailCardRejectDialogForm
    }): void => {
      updateApplication(
        {
          attributes: {
            studentDecisions: {
              [studentDecision]: {
                studentResponse: StudentDecisionResponse.REJECTED,
                decisionDate: new Date().toISOString(),
                reason: data.reason,
              },
            },
          },
        },
        {
          onSuccess: onSuccess(onSuccessProps),
          onError,
        },
      )
    }

  const getDecisionData = (): DecisionData[] => {
    const metaFiles = (application as StudentApplicationWithFiles)?.meta?.files
    const decisionFiles = getFilesOfType(
      [
        DocumentTags.PRE_OFFER_LETTER,
        DocumentTags.FINAL_OFFER_LETTER,
        DocumentTags.WAITLIST_LETTER,
        DocumentTags.DECLINED_LETTER,
      ],
      application?.attributes?.files as FileData,
    )

    const documentTagToDecisionType: Record<DecisionDocumentTags, DecisionType | undefined> = {
      [DocumentTags.PRE_OFFER_LETTER]: DecisionType.PRE_OFFER,
      [DocumentTags.WAITLIST_LETTER]: DecisionType.WAITLIST,
      [DocumentTags.FINAL_OFFER_LETTER]: DecisionType.FINAL_OFFER,
      [DocumentTags.DECLINED_LETTER]: undefined,
    }

    const decisions = Object.keys(decisionFiles).map((key): DecisionData => {
      const date = decisionFiles[key]?.uploadedAt
      const documentTag = decisionFiles[key]?.type as unknown as DecisionDocumentTags
      const type = documentTagToDecisionType[documentTag]
      return {
        name: decisionFiles[key]?.fileName ?? '',
        url: metaFiles[key]?.download?.url ?? '#',
        uploadedAt: date ? new Date(date) : null,
        documentTag: documentTag,
        type,
        hasOfferConditions:
          documentTag === DocumentTags.DECLINED_LETTER ? false : hasOfferConditions,
        decision: type ? (studentDecisions?.[type] as Decision) : undefined,
      }
    })

    decisions.sort((a, b) => {
      if (a.uploadedAt === null) return -1
      if (b.uploadedAt === null) return 1
      return new Date(a.uploadedAt) > new Date(b.uploadedAt) ? -1 : 1
    })

    const typesIncluded = new Set()

    let uniqueDecisions: DecisionData[] = []

    decisions.forEach(decision => {
      if (decision.documentTag !== undefined && !typesIncluded.has(decision.documentTag)) {
        uniqueDecisions.push(decision)
        typesIncluded.add(decision.documentTag)
      }
    })

    return uniqueDecisions
  }

  return (
    <Flex direction="column" gap={6}>
      <Card>
        <Card.Header>
          <ApplicationDetailDecisionLetterHeading />
        </Card.Header>
        <Card.Divider mt={2} mb={3} />
        <Flex direction="column" gap={6}>
          {getDecisionData().map(decision => {
            const response = decision.decision?.studentResponse
            const isPendingPreOffer =
              decision.type === DecisionType.PRE_OFFER &&
              (response === DecisionResponseType.PENDING || !response)

            const showOfferConditions: boolean =
              (isPendingPreOffer ||
                decision.documentTag === DocumentTags.FINAL_OFFER_LETTER ||
                decision.documentTag === DocumentTags.WAITLIST_LETTER) &&
              decision.hasOfferConditions

            return (
              <ApplicationDetailCard
                key={`studentDecision-${decision.type}`}
                title={getTitleByType(decision.documentTag)}
                status={response ?? undefined}
                issuedOn={decision.uploadedAt ?? undefined}
                hasOfferConditions={decision.hasOfferConditions}
                statusChangedOn={
                  decision.decision?.decisionDate
                    ? new Date(decision.decision?.decisionDate)
                    : undefined
                }
                file={decision}
                rejectDialog={
                  isPendingPreOffer && decision.type
                    ? {
                        onSubmit: onSubmitRejectOffer(decision.type),
                      }
                    : undefined
                }
                onOfferConditionsClick={
                  showOfferConditions ? props.onOfferConditionsClick : undefined
                }
                loading={isUpdatingApplication}
                acceptDialog={
                  isPendingPreOffer
                    ? {
                        programName: application?.attributes?.programSelected?.program?.name ?? '',
                        campusName: application?.attributes?.programSelected?.campus?.name ?? '',
                        intakeTermName:
                          application?.attributes?.programSelected?.programIntakeTerm?.name ?? '',
                        onSubmit: onSubmitAcceptOffer(decision.type),
                      }
                    : undefined
                }
              />
            )
          })}
        </Flex>
      </Card>

      {props.school?.additionalDocuments.length ? (
        <Card>
          <Card.Header>
            <Flex direction="column" gap={2}>
              <Flex.Item>
                <Heading level={2} variant="titleS">
                  Additional Documents Received
                </Heading>
              </Flex.Item>
              <Text contrast="mid">
                The following documents have been shared by the school. Please download them, as
                they contain important information that will assist you in the next steps of the
                process.
              </Text>
            </Flex>
          </Card.Header>
          <Card.Divider mt={2} mb={3} />
          <ApplicationDetailAdditionalDocuments files={props.school.additionalDocuments} />
        </Card>
      ) : null}
    </Flex>
  )
}
