import { FileMetaData } from '@applyboard/school-applications-types-lib'
import { DocumentTags } from '../../../utils/enums'
import { StudentApplication } from '../types'
import { ApplicationDocument } from './ApplicationDocument'
import { IApplicationDocument, LanguageExamHistoryRecord, PassportHistoryRecord } from './types'

export class UploadedApplicationDocument extends ApplicationDocument {
  protected constructor(app: StudentApplication, id: string) {
    const fileRef = app.attributes?.files?.[id]
    if (!fileRef) throw new Error('ArgumentError - Could not find file details')

    const fileMeta = 'meta' in app && 'files' in app.meta ? app.meta.files[id] : undefined

    super({
      id,
      uploadDate: fileRef.uploadedAt || fileRef.updatedAt || undefined,
      tags: fileRef.type ? [fileRef.type as unknown as DocumentTags] : [],
      url: fileMeta?.download?.url,
      name: fileRef.fileName,
      section: fileRef.sectionReference,
      history: [],
    })
  }

  static create(
    app: StudentApplication,
    id: string,
    options: { includeHistory?: boolean } = {},
  ): IApplicationDocument {
    let newDoc = new UploadedApplicationDocument(app, id)
    const primaryType: DocumentTags = newDoc.tags[0]

    if (options.includeHistory) {
      const fileHist = Object.entries(app.attributes?.files || {}).filter(([id, file]) => {
        if (!file || !file.type) return false

        return (
          newDoc.id !== id &&
          this.isSameTag(newDoc, file as FileMetaData) &&
          this.isSameSection(newDoc, file as FileMetaData)
        )
      })
      newDoc.history.push(
        ...fileHist
          .map(([id, _file]) => UploadedApplicationDocument.create(app, id))
          .filter((file): file is UploadedApplicationDocument => file !== null)
          .sort((record1, record2) => Number(record2.uploadDate) - Number(record1.uploadDate)),
      )
    }

    switch (primaryType) {
      case DocumentTags.PASSPORT:
        const passportDoc = newDoc as PassportHistoryRecord
        passportDoc.context = {
          passportExpiry: '',
          // TODO: Get from new passport service API
          // app.attributes?.statusAndCitizenship?.passports?.[newDoc.section]?.passportExpiry,
          passportNumber: '',
          // TODO: Get from new passport service API
          // app.attributes?.statusAndCitizenship?.passports?.[newDoc.section]?.passportNumber,
        }
        return passportDoc
      case DocumentTags.LANGUAGE_TEST_OTHER: {
        const otherLanguageDoc = newDoc as LanguageExamHistoryRecord
        const otherExamData =
          app.attributes?.languageProficiency?.languageProficiencyData?.[newDoc.section]
        otherLanguageDoc.context = {
          description: otherExamData?.description,
        }
        return otherLanguageDoc
      }
      case DocumentTags.LANGUAGE_TEST_IELTS:
      case DocumentTags.LANGUAGE_TEST_PTE:
      case DocumentTags.LANGUAGE_TEST_TOEFL: {
        const languageDoc = newDoc as LanguageExamHistoryRecord
        const examData =
          app.attributes?.languageProficiency?.languageProficiencyData?.[newDoc.section]
        languageDoc.context = {
          overallScore: examData?.overallScore,
          certificateNumber: examData?.certificateNumber,
          testDate: examData?.testDate,
          subscores: {
            listening: examData?.subscores?.listening,
            reading: examData?.subscores?.reading,
            speaking: examData?.subscores?.speaking,
            writing: examData?.subscores?.writing,
          },
        }
        return languageDoc
      }
      default:
        return newDoc
    }
  }

  static isSameTag(appDoc: ApplicationDocument, potentialHistory: FileMetaData): boolean {
    const potentialHistoryDocType = potentialHistory.type as unknown as DocumentTags
    switch (potentialHistoryDocType) {
      case DocumentTags.LANGUAGE_TEST_IELTS:
      case DocumentTags.LANGUAGE_TEST_PTE:
      case DocumentTags.LANGUAGE_TEST_TOEFL:
      case DocumentTags.LANGUAGE_TEST_OTHER:
      case DocumentTags.LANGUAGE_TEST:
        return (
          appDoc.tags.includes(DocumentTags.LANGUAGE_TEST_IELTS) ||
          appDoc.tags.includes(DocumentTags.LANGUAGE_TEST_PTE) ||
          appDoc.tags.includes(DocumentTags.LANGUAGE_TEST_TOEFL) ||
          appDoc.tags.includes(DocumentTags.LANGUAGE_TEST_OTHER) ||
          appDoc.tags.includes(DocumentTags.LANGUAGE_TEST)
        )
      default:
        return appDoc.tags.includes(potentialHistory.type as unknown as DocumentTags)
    }
  }

  static isSameSection(appDoc: ApplicationDocument, potentialHistory: FileMetaData): boolean {
    const potentialHistoryDocType = potentialHistory.type as unknown as DocumentTags
    switch (potentialHistoryDocType) {
      case DocumentTags.PASSPORT:
      case DocumentTags.LANGUAGE_TEST_IELTS:
      case DocumentTags.LANGUAGE_TEST_PTE:
      case DocumentTags.LANGUAGE_TEST_TOEFL:
      case DocumentTags.LANGUAGE_TEST_OTHER:
      case DocumentTags.LANGUAGE_TEST:
        return true
      case DocumentTags.CERTIFICATE:
      case DocumentTags.TRANSCRIPTS:
        return appDoc.section === potentialHistory.sectionReference
      default:
        return false
    }
  }
}
