import { useUpdateApplication } from '../../../hooks'
import { GenericError } from '../../../utils'
import { AdditionalDocumentsTab } from './AdditionalDocumentsTab'
import { ApplicationFormsProps } from './ApplicationForms'
import { ContactInformationTab } from './ContactInformationTab'
import { EducationHistoryTab } from './EducationHistoryTab'
import { LanguageProficiencyTab } from './LanguageProficiencyTab'
import { PersonalInformationTab } from './PersonalInformationTab'
import { useGetTimelineItems } from '../../../hooks/useGetTimelineItems'
import { TimelineTabs } from '../../../utils/enums'

export function FormTab(
  props: Omit<ApplicationFormsProps, 'onError'> & {
    onError: (err: GenericError) => void
    updateApplication: ReturnType<typeof useUpdateApplication>['updateApplication']
    formId: string
  },
) {
  const timelineItems = useGetTimelineItems(props.application)

  //selected tab can increase higher than the timeline, we assume it's the last timeline
  const index = props.selectedTab > timelineItems.length ? timelineItems.length : props.selectedTab

  switch (timelineItems[index - 1].key) {
    case TimelineTabs.OTHER_REQUIRED_DOCUMENTS:
      return (
        <AdditionalDocumentsTab
          selectedTab={props.selectedTab}
          application={props.application}
          onSuccess={props.onSuccess}
          onError={props.onError}
          submitApplication={props.submitApplication}
          disabled={props.disabled}
          updateApplication={props.updateApplication}
          formId={props.formId}
        />
      )
    case TimelineTabs.LANGUAGE_PROFICIENCY:
      return (
        <LanguageProficiencyTab
          onSuccess={props.onSuccess}
          application={props.application}
          onError={props.onError}
          disabled={props.disabled}
          updateApplication={props.updateApplication}
          formId={props.formId}
        />
      )
    case TimelineTabs.EDUCATION_HISTORY:
      return (
        <EducationHistoryTab
          onSuccess={props.onSuccess}
          application={props.application}
          onError={props.onError}
          disabled={props.disabled}
          updateApplication={props.updateApplication}
          formId={props.formId}
        />
      )
    case TimelineTabs.CONTACT_INFORMATION:
      return (
        <ContactInformationTab
          onSuccess={props.onSuccess}
          application={props.application}
          onError={props.onError}
          disabled={props.disabled}
          updateApplication={props.updateApplication}
          formId={props.formId}
        />
      )
    default:
      return (
        <PersonalInformationTab
          onSuccess={props.onSuccess}
          application={props.application}
          onError={props.onError}
          disabled={props.disabled}
          updateApplication={props.updateApplication}
          formId={props.formId}
        />
      )
  }
}
