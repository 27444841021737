import { Dialog, Flex, Notice, Text } from '@applyboard/crystal-ui'
import { Dispatch, SetStateAction } from 'react'
import { FlywireButton } from '../../../components/Payments'
import { StudentApplication } from '../../../components/Application/types'

export function PayAndSubmitApprovalDialog(props: {
  showSubmitApprovalDialog: boolean
  setShowSubmitApprovalDialog: Dispatch<SetStateAction<boolean>>
  application: StudentApplication
}) {
  if (!props.showSubmitApprovalDialog) {
    return null
  }

  return (
    <Dialog
      onOpenChange={open => {
        props.setShowSubmitApprovalDialog(open)
      }}
      open={props.showSubmitApprovalDialog}
    >
      <Dialog.Heading>Ready to submit?</Dialog.Heading>
      <Dialog.Content>
        <Flex direction="column" justify="center" align="center" gap={4}>
          <Notice actionText="" intent="primary" width="fill">
            Please note that you will not be able to make any changes to your application after
            submission.
          </Notice>
          <img src="/hands-high-five.svg" alt="High Five" width="300px" height="300px" />
          <Text variant="bodyM">Well done! Your application is ready for submission.</Text>
          <Text variant="bodyM" contrast="low" align="center">
            But first, you will need to pay the non-refundable application fee. Once your payment is
            processed, your application will automatically be submitted.
          </Text>
        </Flex>
      </Dialog.Content>
      <Dialog.Actions>
        <Dialog.CloseButton width="fill">Cancel</Dialog.CloseButton>
        <FlywireButton
          onClick={() => {
            props.setShowSubmitApprovalDialog(false)
          }}
          application={props.application}
        />
      </Dialog.Actions>
    </Dialog>
  )
}
