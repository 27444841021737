import { RawApplicationResponse } from '../../../hooks/useGetApplication'
import { ApplicationForm } from './ApplicationForm'
import { FileData } from '@applyboard/school-applications-types-lib'
import { StudentApplication } from '../types'
import { ApplicationFormWrapper } from './ApplicationFormWrapper'
import { Ref } from 'react'

export type ApplicationFormsProps = {
  disabled?: boolean
  selectedTab: number
  application: StudentApplication
  onSuccess: (response?: RawApplicationResponse) => void
  onError?: (error: Error) => void
  submitApplication?: () => void
  onPreviousTabButton?: () => void
  focusRef?: Ref<HTMLButtonElement> | null
}

export function ApplicationForms(props: ApplicationFormsProps) {
  return (
    <ApplicationForm fileData={props.application?.attributes?.files as FileData}>
      <ApplicationFormWrapper {...props} />
    </ApplicationForm>
  )
}
