import { ComponentType, useState } from 'react'
import {
  Button,
  ButtonLink,
  createForm,
  Flex,
  Heading,
  Text,
  TextInput,
  TextInputProps,
  useToasts,
} from '@applyboard/crystal-ui'
import { ArrowLeftOutlineIcon } from '@applyboard/ui-icons'

import { useForgotPassword } from '../../hooks'
import { emailValidate } from '../../utils/fieldValidations'

import { ForgotPasswordConfirmationPage } from './ForgotPasswordConfirmationPage'

const { Form, Field, useFieldValues } = createForm<{ email: string }>()

export function ForgotPasswordForm() {
  const toast = useToasts()

  const [success, setSuccess] = useState<boolean>(false)
  const { forgotPassword, isLoadingForgotPassword } = useForgotPassword()

  return (
    <Form
      defaultValues={{
        email: '',
      }}
      onSubmit={({ email }: { email: string }) => {
        forgotPassword(
          {
            email,
          },
          {
            onSuccess: () => {
              toast.positive('Reset password email sent')
              setSuccess(true)
            },
            onError: err => {
              if (err instanceof Error) {
                toast.negative(err)
              }
            },
          },
        )
      }}
    >
      {success ? (
        <ForgotPasswordSuccess />
      ) : (
        <Flex gap={10} direction="column">
          <Flex align="center" wrap={true} gap={10}>
            <ButtonLink
              href="/login"
              intent="secondary"
              emphasis="highlighted"
              leadIcon={ArrowLeftOutlineIcon}
              aria-label="back"
              mr={4}
            />
            <Heading level={1} variant="headlineS">
              Forgot your password
            </Heading>
          </Flex>
          <Flex gap={6} direction="column">
            <Text variant="bodyL">
              Enter the email associated with your account and we’ll send you an email with a link
              to reset your password.
            </Text>
            <Field
              as={TextInput as ComponentType<TextInputProps>}
              name="email"
              label="Email"
              required="Email is required"
              validate={(value: string) => {
                return emailValidate(value) || 'This email is invalid.'
              }}
            />
          </Flex>
          <ForgotPasswordSubmitButton loading={isLoadingForgotPassword} />
        </Flex>
      )}
    </Form>
  )
}

function ForgotPasswordSuccess() {
  const { email } = useFieldValues(['email'])

  return <ForgotPasswordConfirmationPage username={email ?? ''} />
}

type ForgotPasswordSubmitButtonProps = {
  loading: boolean
}

function ForgotPasswordSubmitButton(props: Readonly<ForgotPasswordSubmitButtonProps>) {
  const { email } = useFieldValues(['email'])

  return (
    <Button
      intent="primary"
      loading={props.loading}
      type="submit"
      width="fill"
      disabled={!emailValidate(email)}
    >
      Send link
    </Button>
  )
}
