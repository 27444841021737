import { Intent } from '@applyboard/crystal-ui/lib/types/design'
import { DecisionDocumentTags, DocumentTags } from '../../../../../utils/enums'
import { DecisionResponseType } from '@applyboard/school-applications-types-lib/dist/src/backend/applications-api/resources/applications/student-decisions'

export function getHelperText(
  documentTag: DecisionDocumentTags,
  status: DecisionResponseType | undefined,
  hasCondition: boolean,
):
  | {
      label: string
      intent: Intent
    }
  | undefined {
  switch (documentTag) {
    case DocumentTags.DECLINED_LETTER:
      return {
        label:
          'We regret to inform you that, after careful consideration of your application, the school has decided to decline your offer.',
        intent: 'negative',
      }
    case DocumentTags.WAITLIST_LETTER:
      return hasCondition
        ? {
            label: 'Please download the attached letter, review the offer conditions, and respond.',
            intent: 'warning',
          }
        : undefined
    case DocumentTags.PRE_OFFER_LETTER:
      if (status === DecisionResponseType.REJECTED) {
        return {
          label: 'A decision has been made by you to reject the pre-offer letter.',
          intent: 'negative',
        }
      }
      return (!status || status === DecisionResponseType.PENDING) && hasCondition
        ? {
            label: 'Please download the attached letter, review the offer conditions, and respond.',
            intent: 'positive',
          }
        : undefined
    case DocumentTags.FINAL_OFFER_LETTER:
    default:
      return hasCondition
        ? {
            label: 'Please download the attached letter, review the offer conditions, and respond.',
            intent: 'positive',
          }
        : undefined
  }
}
