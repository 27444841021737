import { useOktaAuth } from '@okta/okta-react'
import { useAuthManager } from '../../hooks'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { Loading } from '../Loading'

export function PublicRoute() {
  const useOkta = useOktaAuth()
  const { isLoggedIn } = useAuthManager()
  const location = useLocation()

  if (useOkta) {
    if (!useOkta.authState) return <Loading height="100vh" />

    if (useOkta.authState.isAuthenticated || location.pathname === '/login') {
      return <Navigate to={'/'} />
    }

    return <Outlet />
  }

  if (isLoggedIn() && location.pathname === '/login') {
    return <Navigate to={'/'} />
  }

  return <Outlet />
}
