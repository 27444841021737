import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { useAuthManager } from '../../hooks'
import { useOktaAuth } from '@okta/okta-react'
import { Loading } from '../Loading'

export const LOCAL_STORAGE_REDIRECT_KEY = 'redirect'

export function ProtectedRoute() {
  const location = useLocation()
  const { isLoggedIn } = useAuthManager()
  const useOkta = useOktaAuth()

  if (useOkta) {
    if (!useOkta.authState) return <Loading height="100vh" />

    // User is logged in
    if (useOkta.authState.isAuthenticated) {
      const redirect = localStorage.getItem(LOCAL_STORAGE_REDIRECT_KEY)
      if (redirect) {
        localStorage.removeItem(LOCAL_STORAGE_REDIRECT_KEY)
        return <Navigate to={redirect} />
      }
      return <Outlet />
    }

    // User is not logged in
    if (!useOkta.authState.isAuthenticated) {
      localStorage.setItem(LOCAL_STORAGE_REDIRECT_KEY, location.pathname + location.search)
      useOkta.oktaAuth.signInWithRedirect() // Redirect to Okta login
      return null // Prevent rendering
    }
  }

  // Logged in via Cognito
  if (isLoggedIn()) {
    return <Outlet />
  }

  // User is not logged in via Cognito
  localStorage.setItem(LOCAL_STORAGE_REDIRECT_KEY, location.pathname + location.search)
  return <Navigate to={'/login'} state={{ from: location.pathname + location.search }} />
}
