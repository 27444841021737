import { Security } from '@okta/okta-react'
import { PropsWithChildren } from 'react'
import { useGetSpaConfig } from '../hooks/useGetSpaConfig'
import { Loading } from '../components/Loading'
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js'
import { RestoreOriginalUriFunction } from '@okta/okta-react/bundles/types/OktaContext'
import { useNavigate } from 'react-router-dom'

export function ConfigProvider(props: PropsWithChildren) {
  const { config, isLoading } = useGetSpaConfig()
  const navigate = useNavigate()

  if (isLoading) {
    return <Loading height="100vh" />
  }

  const isOktaEnabled = !!(config?.issuer && config?.clientId)

  if (!isOktaEnabled) {
    return <>{props.children}</>
  }

  const restoreOriginalUri: RestoreOriginalUriFunction = async (
    _oktaAuth: OktaAuth,
    originalUri: string,
  ) => {
    navigate(toRelativeUrl(originalUri || '/', window.location.origin), { replace: true })
  }

  const oktaAuthInit = new OktaAuth({
    issuer: config.issuer,
    clientId: config.clientId,
    redirectUri: window.location.origin + config.redirectUri,
  })

  return (
    <Security oktaAuth={oktaAuthInit} restoreOriginalUri={restoreOriginalUri}>
      {props.children}
    </Security>
  )
}
