import { Card, Flex, Heading, Text, Box } from '@applyboard/crystal-ui'
import { PropsWithChildren, Ref } from 'react'
import { FormSubmitButtonGroup } from './FormSubmitButtonGroup'
import styled from '@emotion/styled'
import { up } from '../../../utils'

type ApplicationFormCardProps = {
  disabled?: boolean
  cardNumber: number
  totalCards: number
  icon?: string
  title: string
  isLoading?: boolean
  submitButtonLabel?: string
  onSubmitApplication?: () => void
  onPreviousTabButton?: () => void
  formId: string
  focusRef?: Ref<HTMLButtonElement> | null
}

const ActionsContainer = styled.div(({ theme }) => ({
  position: 'fixed',
  bottom: 0,
  left: 0,
  width: '100%',
  height: '80px',
  backgroundColor: theme.colors.bgRegular,
  borderTop: `1px solid ${theme.colors.borderRegular}`,
  padding: '0 24px',
  marginTop: '100px',

  [up('md')]: {
    display: 'none',
  },
}))

export function ApplicationFormCard(props: PropsWithChildren<ApplicationFormCardProps>) {
  return (
    <>
      <Box pb={14}>
        <Card>
          <Card.Header>
            <Flex justify="between" align="center">
              <Flex direction="column">
                <Text variant="bodyS">
                  {props.cardNumber} of {props.totalCards}
                </Text>
                <Heading level={2} variant="titleM">
                  {props.icon && <span aria-hidden>{props.icon}</span>} {props.title}
                </Heading>
              </Flex>
              <Flex hideBelow="md" gap={4}>
                <FormSubmitButtonGroup
                  onPreviousTabButton={props.onPreviousTabButton}
                  cardNumber={props.cardNumber}
                  disabled={props.disabled}
                  isLoading={props.isLoading}
                  submitButtonLabel={props.submitButtonLabel}
                  onSubmitApplication={props.onSubmitApplication}
                  formId={props.formId}
                  focusRef={props.focusRef}
                />
              </Flex>
            </Flex>
          </Card.Header>
          <Card.Divider mt={2} mb={2} />
          <Flex direction="column" gap={4}>
            {props.children}
          </Flex>
        </Card>
      </Box>
      <ActionsContainer>
        <Flex gap={3} align="center" justify="end" height="fill" direction="row">
          <FormSubmitButtonGroup
            cardNumber={props.cardNumber}
            disabled={props.disabled}
            fullWidth
            isLoading={props.isLoading}
            submitButtonLabel={props.submitButtonLabel}
            onPreviousTabButton={props.onPreviousTabButton}
            onSubmitApplication={props.onSubmitApplication}
            formId={props.formId}
          />
        </Flex>
      </ActionsContainer>
    </>
  )
}
