import { Text } from '@applyboard/crystal-ui'
import styled from '@emotion/styled'
import { getHelperText } from './utils'
import { Intent } from '@applyboard/crystal-ui/lib/types/design'
import { DecisionDocumentTags } from '../../../../../utils/enums'
import { DecisionResponseType } from '@applyboard/school-applications-types-lib/dist/src/backend/applications-api/resources/applications/student-decisions'

type ApplicationDetailCardHelperProps = Readonly<{
  decisionDocumentTag: DecisionDocumentTags
  status?: DecisionResponseType
  hasCondition: boolean
}>

export function ApplicationDetailCardHelper(props: ApplicationDetailCardHelperProps) {
  const helperText = getHelperText(props.decisionDocumentTag, props.status, props.hasCondition)
  return helperText ? (
    <HelperWrapper intent={helperText.intent}>
      <Text variant="bodyS">{helperText.label}</Text>
    </HelperWrapper>
  ) : null
}

type HelperWrapperProps = {
  intent: Intent
}

const HelperWrapper = styled.div<HelperWrapperProps>(({ theme, intent }) => ({
  borderLeftWidth: 4,
  borderLeftStyle: 'solid',
  borderLeftColor:
    intent === 'positive'
      ? theme.colors.iconPositiveHover
      : theme.colors[`icon${intent.charAt(0).toUpperCase() + intent.slice(1)}`],
  paddingBlock: 8,
  paddingLeft: 24,
}))
