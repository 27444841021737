import { Flex, Notice, NoticeProps } from '@applyboard/crystal-ui'
import { format, parseISO } from 'date-fns'
import { ApplicationResourceAttributes } from '@applyboard/school-applications-types-lib/dist/src/backend/applications-api/resources/applications/types'

type DeferralBannerProps = {
  deferralRequests: ApplicationResourceAttributes['deferralRequests']
}

export function DeferralBanner(props: DeferralBannerProps) {
  const latestDeferralRequest = props.deferralRequests
    ? Object.values(props.deferralRequests).sort(
        (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
      )[0]
    : undefined

  if (!latestDeferralRequest) {
    return null
  }

  const startDate = latestDeferralRequest?.programIntakeTerm?.startDate

  let bannerText = ''
  let intent: NoticeProps['intent']

  if (latestDeferralRequest?.status === 'APPROVED' && startDate) {
    bannerText = `Your deferral request has been accepted. Your new intake is ${format(
      parseISO(startDate),
      'MMM yyyy',
    )}.`
    intent = 'positive'
  } else if (latestDeferralRequest?.status === 'DECLINED') {
    bannerText = 'Your deferral request has been denied.'
    intent = 'negative'
  } else {
    return null
  }

  return (
    <Flex grow={1}>
      <Notice intent={intent}>{bannerText}</Notice>
    </Flex>
  )
}
