import {
  ApplicationResourceAttributes,
  ProgramIntakeTerm,
} from '@applyboard/school-applications-types-lib'
import { SetAllFieldsOptional } from 'schools-domain-backend-utils'

import { ApiStudentRequest, request } from '../utils/request'
import { ApplicationState } from '../hooks'

import { useAuthMutation } from './auth'
import { StudentApplication } from '../components/Application/types'

type CreateApplicationParams = {
  intakeId: Exclude<ProgramIntakeTerm, undefined>['id']
}

type RawApplicationsResponse = {
  data: StudentApplication
}

export function useCreateApplication() {
  const { isPending, mutate, data } = useAuthMutation(async (params: CreateApplicationParams) => {
    const programData: SetAllFieldsOptional<ApplicationResourceAttributes['programSelected']> = {
      priority: 0,
      programIntakeId: params.intakeId,
    }

    return await request<RawApplicationsResponse>(
      new ApiStudentRequest('/applications', {
        method: 'POST',
        body: {
          data: {
            type: 'application',
            attributes: {
              programSelected: programData,
              applicationState: ApplicationState.DRAFT,
            },
          },
        },
      }),
      {
        isExpectedResponse: (res): res is RawApplicationsResponse => res,
      },
    )
  })

  return {
    isCreatingApplication: isPending,
    createApplication: mutate,
    application: data?.data || null,
  }
}
