import { useQueryClient } from '@tanstack/react-query'
import { useAuthMutation } from './auth'
import { ApiStudentRequest, request } from '../utils/request'
import { UpdatePassportByIdRequest } from '@backend/students-api/routes/documents/passports/{id}/PATCH/types'
import { PassportResource } from '@backend/resources/student-document'

type UpdatePassportProps = {
  id?: string
}

type UpdatePassportParams = UpdatePassportByIdRequest['data']['attributes']

type RawPassportResponse = {
  data: PassportResource
}

export function useUpdatePassport(props: UpdatePassportProps) {
  const queryClient = useQueryClient()

  const { isPending, mutate } = useAuthMutation(
    async (params: UpdatePassportParams) => {
      return await request<RawPassportResponse>(
        new ApiStudentRequest(`/documents/passports/${props.id}`, {
          method: 'PATCH',
          body: {
            data: {
              type: 'passport',
              attributes: {
                passportNumber: params?.passportNumber ?? null,
                expiryDate: params?.expiryDate ?? null,
              },
            },
          },
        }),
        {
          isExpectedResponse: (res): res is RawPassportResponse => res,
        },
      )
    },
    {
      onSuccess: data => {
        queryClient.setQueryData(['passport'], data)
      },
    },
  )

  return {
    isUpdatingPassport: isPending,
    updatePassport: mutate,
  }
}
