import { useApplicationFormContext } from './ApplicationForm'
import { FileData } from '@applyboard/school-applications-types-lib'
import { GenericError, additionalDocumentTypeOptions, hasApplicationFee } from '../../../utils'
import { JsonApiError } from '../../../utils/errors'
import { ApplicationFormCard } from './ApplicationFormCard'
import { Flex } from '@applyboard/crystal-ui'
import { useUpdateApplication } from '../../../hooks'
import { DocumentTags } from '../../../utils/enums'
import { getFilesOfType } from './utils'
import { FormTab } from './FormTab'
import { ApplicationFormsProps } from './ApplicationForms'
import { TimelineObject, useGetTimelineItems } from '../../../hooks/useGetTimelineItems'

export function ApplicationFormWrapper(props: ApplicationFormsProps) {
  const { isUpdatingApplication, updateApplication } = useUpdateApplication({
    id: props.application.id,
  })
  const timelineItems = useGetTimelineItems(props.application)
  const lastTimeline = timelineItems.length
  const { resetFiles, pendingFileUploadState } = useApplicationFormContext()
  const { cardNumber, icon, title, formId } = getCardInformation(props.selectedTab, timelineItems)

  const onError = (err: GenericError) => {
    if (err instanceof JsonApiError && err.code === 'applicationNotDraft')
      return props.onError?.(new Error(''))
    if (err instanceof Error) {
      props.onError?.(err)
    }
  }

  return (
    <Flex grow={1} direction="column">
      <ApplicationFormCard
        cardNumber={cardNumber}
        totalCards={lastTimeline}
        icon={icon}
        title={title}
        isLoading={isUpdatingApplication}
        disabled={props.disabled}
        formId={formId}
        focusRef={props.focusRef}
        submitButtonLabel={
          cardNumber === lastTimeline
            ? hasApplicationFee(props.application)
              ? 'Pay & Submit'
              : 'Save & Submit'
            : undefined
        }
        onPreviousTabButton={props.onPreviousTabButton}
        onSubmitApplication={
          cardNumber === lastTimeline
            ? () => {
                if (props.application) {
                  props.onSuccess({ data: props.application })
                }
                updateApplication(
                  {
                    files: pendingFileUploadState,
                  },
                  {
                    onSuccess: response => {
                      resetFiles(
                        getFilesOfType(
                          Object.keys(additionalDocumentTypeOptions) as DocumentTags[],
                          response.data?.attributes?.files as FileData,
                        ),
                      )
                      if (props.submitApplication) props.submitApplication() // typescript is confused...
                    },
                    onError,
                  },
                )
              }
            : undefined
        }
      >
        <FormTab
          application={props.application}
          disabled={props.disabled}
          onError={onError}
          onSuccess={props.onSuccess}
          selectedTab={props.selectedTab}
          updateApplication={updateApplication}
          submitApplication={props.submitApplication}
          formId={formId}
        />
      </ApplicationFormCard>
    </Flex>
  )
}

/*
In relation with currentStep, selectedStep can go out of bound, assume we're on the last step
 */
function getCardInformation(selectedTab: number, timelineItems: TimelineObject[]) {
  if (selectedTab >= timelineItems.length) {
    return timelineItems[timelineItems.length - 1]
  }

  return timelineItems[selectedTab - 1]
}
