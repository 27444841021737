import { Button, Flex } from '@applyboard/crystal-ui'
import { ChevronLeftOutlineIcon, ChevronRightOutlineIcon } from '@applyboard/ui-icons'
import { useEffect, useState } from 'react'

import { Loading } from '../../../components/Loading'
import { useGetIntakeSearch } from '../../../hooks/useGetIntakeSearch'
import { useProgramFilterSearchParams } from '../../../hooks'

import { PaginationDetails } from './PaginationDetails'
import { ProgramCard } from './ProgramCard'
import { StudentApplication } from '../../../components/Application/types'

type ProgramListProps = {
  loading: boolean
  onSubmit: (intakeId: string) => void
  currentApplication: StudentApplication['attributes']
}

export function ProgramList(props: ProgramListProps) {
  const { filters, updateFilters } = useProgramFilterSearchParams()
  const [selectedIntakeId, setSelectedIntakeId] = useState<string>()
  const {
    intakeSearch,
    intakeSearchMeta,
    isLoadingIntakeSearch,
    refetchIntakeSearch,
    isFetchingIntakeSearch,
    isStaleIntakeSearch,
  } = useGetIntakeSearch({
    pageNumber: Number(filters.pageNumber),
    campuses: filters.campuses,
    intakeTerms: filters.startDates,
    statuses: filters.statuses,
    q: filters.q,
  })

  useEffect(() => {
    if (isStaleIntakeSearch) {
      refetchIntakeSearch()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (isLoadingIntakeSearch || isFetchingIntakeSearch) {
    return <Loading />
  }

  return (
    <Flex direction="column" gap={4}>
      {intakeSearch.map(intake => (
        <ProgramCard
          key={`intake-${intake.id}-1`}
          disabled={selectedIntakeId !== intake.id && props.loading}
          loading={selectedIntakeId === intake.id && props.loading}
          onSubmit={() => {
            setSelectedIntakeId(intake.id)
            props.onSubmit(intake.id)
          }}
          intake={intake}
          currentApplication={props.currentApplication}
        />
      ))}
      {intakeSearchMeta?.pagination ? (
        <Flex align="center" justify="between" pt={2}>
          <PaginationDetails pagination={intakeSearchMeta.pagination} />
          <Flex gap={2}>
            <Button
              aria-label="Previous page"
              leadIcon={ChevronLeftOutlineIcon}
              emphasis="highlighted"
              onClick={() => {
                if (intakeSearchMeta.pagination.currentPage - 1 >= 1) {
                  updateFilters({
                    pageNumber: `${intakeSearchMeta.pagination.currentPage - 1}`,
                  })
                }
              }}
            />
            <Button
              aria-label="Next page"
              leadIcon={ChevronRightOutlineIcon}
              emphasis="highlighted"
              onClick={() => {
                if (
                  intakeSearchMeta.pagination.currentPage + 1 <=
                  Math.ceil(intakeSearchMeta.pagination.total / intakeSearchMeta.pagination.perPage)
                ) {
                  updateFilters({
                    pageNumber: `${intakeSearchMeta.pagination.currentPage + 1}`,
                  })
                }
              }}
            />
          </Flex>
        </Flex>
      ) : null}
    </Flex>
  )
}
