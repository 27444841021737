import { TagProps } from '@applyboard/crystal-ui'
import { DecisionDocumentTags, DocumentTags } from '../../../../../utils/enums'

export function getIntent(documentTag: DecisionDocumentTags): TagProps['intent'] {
  if (documentTag === DocumentTags.DECLINED_LETTER) {
    return 'negative'
  }
  if (documentTag === DocumentTags.WAITLIST_LETTER) {
    return 'secondary'
  }

  return 'positive'
}
