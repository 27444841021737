import { useLocation, useNavigate } from 'react-router-dom'
import {
  Box,
  Button,
  ButtonLink,
  createForm,
  Flex,
  Heading,
  Link,
  Text,
  TextInput,
  TextInputProps,
  useToasts,
} from '@applyboard/crystal-ui'

import { useAuthManager } from '../../hooks'
import { OnBoardingBox } from '../../components/OnBoardingBox'
import { ArrowLeftOutlineIcon } from '@applyboard/ui-icons'
import { LOCAL_STORAGE_REDIRECT_KEY } from '../../components/ProtectedRoute/ProtectedRoute'
import { ComponentType } from 'react'

const { Form, Field } = createForm<{ email: string; password: string }>()

export function LoginPage() {
  const toast = useToasts()
  const navigate = useNavigate()
  const location = useLocation()
  const { login, isLoggingIn } = useAuthManager()

  return (
    <OnBoardingBox>
      <Flex gap={10} direction="column">
        <Flex align="center" wrap={true} gap={10}>
          <ButtonLink
            href="/signup"
            intent="secondary"
            emphasis="highlighted"
            leadIcon={ArrowLeftOutlineIcon}
            aria-label="back"
            mr={4}
          />
          <Heading level={1} variant="headlineS">
            Log into your account
          </Heading>
        </Flex>
        <Form
          defaultValues={{
            email: '',
            password: '',
          }}
          onSubmit={async ({ email, password }) => {
            login(
              { email, password },
              {
                onSuccess: () => {
                  const localStorageRedirect = window.localStorage.getItem(
                    LOCAL_STORAGE_REDIRECT_KEY,
                  )
                  if (localStorageRedirect) {
                    window.localStorage.removeItem(LOCAL_STORAGE_REDIRECT_KEY)
                    navigate(localStorageRedirect, { replace: true })
                  } else if (location.state?.from) {
                    navigate(location.state?.from, { replace: true })
                  } else {
                    navigate('/', { replace: true })
                  }
                },
                onError: err => {
                  if (err instanceof Error) {
                    toast.negative(err)
                  }
                },
              },
            )
          }}
        >
          <Flex gap={6} pb={4} direction="column">
            <Field
              as={TextInput as ComponentType<TextInputProps>}
              name="email"
              label="Email"
              required="Email is required"
            />
            <Field
              as={TextInput as ComponentType<TextInputProps>}
              name="password"
              label="Password"
              required="Password is required"
              type="password"
            />
          </Flex>
          <Box pb={10}>
            <Text variant="bodyM">
              <Link href="/forgot-password" variant="standalone">
                Forgot password?
              </Link>
            </Text>
          </Box>
          <Button intent="primary" loading={isLoggingIn} type="submit" width="fill">
            Log in
          </Button>
        </Form>
      </Flex>
      <Flex justify="center" pt={6}>
        <Text>
          Don't have an account? <Link href="/signup">Sign up</Link>
        </Text>
      </Flex>
    </OnBoardingBox>
  )
}
